
import { orderBy } from 'lodash'
import { useLocalstorage } from '../localStorage/store'
import type {Favorite} from '~/models/favorites'
import type {Assortment} from '~/models/assortments'
import {useAxiosClient} from "~/use/axios/client";
import {AssortmentApi} from "~/gen/openapi/sblService";

const { persist } = useLocalstorage()

const state = reactive({
  assortments: persist('assortments', [] as Assortment[]),
  favorites: persist('favoriteAssortments', [] as Favorite[]),
  lastFetchedAssortments: persist<number>('lastFetchedAssortments', 0),
})

const assortments = computed((): Assortment[] => state.assortments)
const favorites = computed((): Favorite[] => orderBy(state.favorites, 'count', 'desc').slice(0, 10))
const lastFetchedAssortments = computed((): number => state.lastFetchedAssortments)

const clearAssortments = (): void => {
  state.assortments = []
  state.favorites = []
}

const setAssortments = (assortments: Assortment[]): void => {
  state.assortments = assortments
}
const addToFavorites = (id: string): void => {
  const existing = state.favorites.find((x: Favorite) => x.id === id)
  if (existing) {
    state.favorites[state.favorites.indexOf(existing)].count++
  } else {
    state.favorites.push({
      id,
      count: 1
    })
  }
}

const loadAssortments = async (force?: boolean): Promise<void> => {
  if (!force &&
      state.assortments.length &&
      lastFetchedAssortments &&
      lastFetchedAssortments.value > Date.now() - 1000 /*ms*/ * 60 /*seconds*/ * 60 /*minutes*/
  ) {
    return
  }

  try {
    const { axiosClient } = useAxiosClient()
    const assortmentApi = new AssortmentApi(undefined, '', axiosClient.value)
    const assortments: Assortment[] = (await assortmentApi.assortmentGetAssortments())?.data
    setAssortments(assortments)
    state.lastFetchedAssortments = Date.now()
  } catch (e) {
    console.error('Failed to load assortments', e)
  }
}

const findAssortmentById = (id?: string): Assortment | undefined =>
  state.assortments.find((assortment) => assortment.Id === id)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAssortmentsStore = () => ({
  assortments,
  clearAssortments,
  favorites,
  addToFavorites,
  loadAssortments,
  findAssortmentById
})
